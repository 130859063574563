import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";

export default function NewPostForm() {
  const [postUsername, setPostUsername] = useState("");
  const [postText, setPostText] = useState("");
  const [postImageLink, setPostImageLink] = useState("");
  const [postGifLink, setPostGifLink] = useState("");
  const [postWebLink, setPostWebLink] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const post_date = new Date().toLocaleDateString();
    const post_time = new Date().toLocaleTimeString();
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const postData = Object.freeze({
      username: postUsername,
      content: postText,
      image_url: postImageLink,
      gif_url: postGifLink,
      link_url: postWebLink,
      date: post_date,
      time: post_time + " " + time_zone + " timezone",
    });

    const resp = await fetch("https://myapp.lmayliffe.workers.dev/posts", {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (resp.status === 200) {
      setPostUsername("");
      setPostText("");
      setPostImageLink("");
      setPostGifLink("");
      setPostWebLink("");
    }
  };

  return (
    <>
      <div className="newPostForm">
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="userName"
                label="User Name"
                autoFocus
                helperText="Please enter a username."
                value={postUsername}
                onChange={(e) => setPostUsername(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={4}
                id="text"
                label="Post Text"
                name="post text"
                value={postText}
                onChange={(e) => setPostText(e.target.value)}
                helperText="Submitted posts may have a slight delay before they are posted and retrieved from the KV namespace."
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="web_link"
                label="Share a link?"
                type="url"
                id="web_link"
                value={postWebLink}
                onChange={(e) => setPostWebLink(e.target.value)}
                helperText="Please enter a valid full URL. Even better if it's from a site with good Preview metadata."
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="image_link"
                label="Share an image link?"
                type="url"
                id="image_link"
                value={postImageLink}
                onChange={(e) => setPostImageLink(e.target.value)}
                helperText="Direct image links get some styling treatment and have max size restrictions while keeping aspect ratio intact."
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="gif_link"
                label="Share a gif link?"
                type="url"
                id="gif_link"
                value={postGifLink}
                onChange={(e) => setPostGifLink(e.target.value)}
                helperText="Gif links get some styling treatment and have max size restrictions while keeping aspect ratio intact."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            endIcon={<SendIcon />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit Post{" "}
          </Button>
        </Box>
      </div>
      {/* <form id="newPostForm" onSubmit={handleSubmit}>
        <div className="mainPostInputs">
          <label>
            Username:
            <input
              type="text"
              value={postUsername}
              onChange={(e) => setPostUsername(e.target.value)}
              placeholder="Enter your username here."
              style={{ width: "200px", margin: "5px" }}
            />
          </label>
          <label>
            Post text:
            <input
              type="textarea"
              value={postText}
              onChange={(e) => setPostText(e.target.value)}
              placeholder="What would you like to share today?"
              style={{ width: "300px", height: "100px", margin: "5px" }}
            />
          </label>
        </div>
        <div className="secondaryPostInputs">
          <label>
            Image Link?
            <input
              type="url"
              value={postImageLink}
              onChange={(e) => setPostImageLink(e.target.value)}
              style={{ width: "auto", height: "auto", margin: "5px" }}
            />
          </label>
          <label>
            Gif Link
            <input
              type="url"
              value={postGifLink}
              onChange={(e) => setPostGifLink(e.target.value)}
              style={{ width: "auto", height: "auto", margin: "5px" }}
            />
          </label>
          <label>
            Web Link
            <input
              type="url"
              value={postWebLink}
              onChange={(e) => setPostWebLink(e.target.value)}
              style={{ width: "auto", height: "auto", margin: "5px" }}
            />
          </label>
          <input type="submit" value="Submit" id="submitButton" />
        </div>
      </form> */}
    </>
  );
}
