import "../App.css";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import { Reactions } from "./helpers/reactions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import NewPostForm from "./newPostForm";

export default function Posts({ posts }) {
  return (
    <div className="appWrapper" id="text">
      <img
        src={process.env.PUBLIC_URL + `/assets/applogo.png`}
        alt="A modified cloudlare logo changed to read 'Cloudflair' instead as a joke."
      />
      <h3>Where cloud lovers connect!</h3>
      <h5>
        ...I'm just trying to make the reviewers chuckle, please don't sue me.
        😊
      </h5>
      <div className="inputWrapper">
        <NewPostForm />
      </div>
      <div className="postWrapper">
        {posts &&
          posts.map((post) => (
            <div key={post.id} className="post">
              <div className="postMetadata" id="text">
                <FontAwesomeIcon icon={faUserCircle} /> <b>{post.username}</b>{" "}
                posted on <b>{post.date || "10/20/2021"}</b> at{" "}
                <b>{post.time || "4:19PM"}</b>
              </div>
              <div className="postTextContent" id="text">
                {post.content}
              </div>

              {post.image_url ? (
                <div className="imageWrapper">
                  <img src={post.image_url} alt={post.content} />
                </div>
              ) : null}

              {post.gif_url ? (
                <img src={post.gif_url} alt={post.content} />
              ) : null}

              {post.link_url ? (
                <LinkPreview
                  url={post.link_url}
                  fallback={"No link preview available"}
                  width={400}
                  height={300}
                  borderRadius={10}
                  descriptionLength={100}
                />
              ) : null}
              <div className="reactionsWrapper">
                <Reactions />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
