import { Router } from "@reach/router";
import { useState, useEffect } from "react";
import Posts from "./components/posts";

function App() {
  const [posts, setPosts] = useState();

  useEffect(() => {
    const getPosts = async () => {
      try {
        const resp = await fetch("https://myapp.lmayliffe.workers.dev/posts", {
          method: "GET",
        });
        const postsResp = await resp.json();
        setPosts(postsResp);
      } catch (err) {
        console.log(err);
      }
    };
    getPosts();
  }, []);

  return (
    <Router>
      <Posts posts={posts} path="/" />
    </Router>
  );
}

export default App;
